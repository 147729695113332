import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import TrackVisibility from "react-on-screen";
import "animate.css";
import navIcon2 from "./assets/img/nav-icon2.svg";
import navIcon3 from "./assets/img/nav-icon3.svg";
import navIcon4 from "./assets/img/nav-icon4.svg";
import navIcon6 from "./assets/img/nav-icon6.svg";
import { CSSDoodle } from "css-doodle/component";

const ethAdd = "8Vb11fvr9f7k74hrhNJEqjGq1hzgBH7FnuwGFbPppump";
const shortenedEthAdd = "8Vb11f...Pppump";
const ticker = "LENNY";
const twitter = "https://x.com/lennyface_sol";
const telegram = "https://t.me/lennyoncto";
const dexscreener =
  "https://dexscreener.com/solana/7ulog7ikjgdalyybdkppxg5pn6q7nuam59fjbcvi4x52";
const dextools =
  "https://www.dextools.io/app/en/solana/pair-explorer/7ULog7ikjgdALYYBdkPPXG5PN6q7nuaM59fjbCVi4X52?t=1726298831277";
const buylink =
  "https://raydium.io/swap/?inputMint=sol&outputMint=8Vb11fvr9f7k74hrhNJEqjGq1hzgBH7FnuwGFbPppump";
const footercontent = "2024 LENNY on Solana. © All rights reserved.";

function importAll(r) {
  let images = [];
  r.keys().map((item, index) => {
    images.push(r(item));
  });

  console.log(images);
  return images;
}

const listOfImages = importAll(
  require.context("./assets/popjpegs", false, /\.(png|jpe?g|svg|gif)$/)
);

function App() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 520px)" });
  const [audio] = useState(new Audio("popjpegs/pwned.mp3"));

  useEffect(() => {
    // Some random colors
    const colors = ["#ffe0f0", "#ffbfdf", "#ffd9ec", "#ffabd5", "#ff8fc7"];

    const numBalls = isMobile ? 10 : 15;
    const balls = [];

    for (let i = 0; i < numBalls; i++) {
      let ball = document.createElement("div");
      ball.innerHTML = `<span>( ͡º ͜ʖ ͡º)</span>`;
      ball.classList.add("ball");
      ball.style.background = `transparent`;
      ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
      ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
      ball.style.transform = `scale(${Math.random() * (1.75 - 0.1) + 0.1})`;
      ball.style.width = `4em`;
      ball.style.height = ball.style.width;

      balls.push(ball);
      document.body.append(ball);
    }

    // Keyframes
    balls.forEach((el, i, ra) => {
      let to = {
        x: Math.random() * (i % 2 === 0 ? -11 : 11),
        y: Math.random() * 12,
      };

      let anim = el.animate(
        [
          { transform: "translate(0, 0)" },
          { transform: `translate(${to.x}rem, ${to.y}rem)` },
        ],
        {
          duration: (Math.random() * (150 - 50) + 50) * 60, // random duration
          direction: "alternate",
          fill: "both",
          iterations: Infinity,
          easing: "linear",
        }
      );
    });
  });

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  return (
    <Container id="main-container">
      <ToastContainer autoClose={5000} />
      <Header />
      <SocialsHeader mobileMode={isMobile} />
      <SpikeBanner />
      <Carousel />
      <BuyGuide />
      <Footer />
      <TickerTape />
      <PlayDiv />
    </Container>
  );
}

function SpikeBanner() {
  return (
    <section id="banner-section">
      <video autoPlay loop muted>
        <source src="popjpegs/sunrise.mp4" type="video/mp4" />
      </video>
      <Container>
        <Row className="align-items-center">
          <Col xs={12}>
            <img id="orangeBanner" alt="" src="popjpegs/main.png" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

function Header() {
  return (
    <Row>
      <Col xs={12}>
        <header className="header">
          <h1>{ticker}</h1>
          <h3>MEET THE LEGENDARY 4CHAN MEME.</h3>
        </header>
      </Col>
    </Row>
  );
}

function DoodleThis() {}

function CssDoodle() {
  const data = `<css-doodle>:doodle {
    @grid: 10x10;
    @size: 100vmax;
    grid-gap: 5px;position: fixed; top: 0; left: 0;
    z-index: -1; 
    }
    
    background-color: hsla(@r(360), 85%, @r(85%, 90%), @r(.6,.9));
    transform: scale(@rand(.1, 1.75));
    border-radius: @rand(0, 100%);
    @shape: clover 5;
      
    animation: test infinite @r(50s, 150s) linear;
    
    @keyframes test {
      0% {
        transform: translate3d(@r(0, 0, 0));
      }
      50% {
        transform: translate3d(@r(-500%, 1000%), @r(-500%, 1000%), 0);
      }
      100% {
        transform: translate3d(0, 0, 0);
      }</css-doodle>`;

  return <div dangerouslySetInnerHTML={{ __html: data }} />;
}

function ContractClip(props) {
  const { mobileMode } = props;
  return (
    <section className="contractClip">
      <p> {mobileMode ? shortenedEthAdd : ethAdd} </p>
    </section>
  );
}

function PlayDiv() {
  const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
      playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
      audio.addEventListener("ended", () => setPlaying(false));
      return () => {
        audio.removeEventListener("ended", () => setPlaying(false));
      };
    }, []);

    return [playing, toggle];
  };

  const [playing, toggle] = useAudio("popjpegs/pwned.mp3");

  return (
    <img
      id="play-btn"
      src="popjpegs/play.gif"
      onClick={toggle}
      alt="Header Img"
    />
  );
}

function MainBanner() {
  return (
    <section className="mainbanner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col
            xs={{ span: 12, order: 2 }}
            md={6}
            xl={7}
            className="mainBannerCols"
          >
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h1 style={{ margin: "20px", textAlign: "center" }}>
                    The Fur-tastic Star <br />
                    on Solana!
                  </h1>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            md={6}
            xl={5}
            id="logo-col"
            className="mainBannerCols"
          >
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <img id="logo" src="popjpegs/poplogo.png" alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

function SocialsHeader(props) {
  const { mobileMode } = props;
  return (
    <Row className="socialsHeader">
      <Col xs={12}>
        <section
          className="contractClip"
          onClick={() => {
            navigator.clipboard.writeText(ethAdd);
            toast.dismiss();
            toast("Addy Copied! ( ͡º ͜ʖ ͡º)");
          }}
        >
          <span> {mobileMode ? shortenedEthAdd : ethAdd} </span>
          <svg
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 14H19C20.1046 14 21 13.1046 21 12V5C21 3.89543 20.1046 3 19 3H12C10.8954 3 10 3.89543 10 5V6.5M5 10H12C13.1046 10 14 10.8954 14 12V19C14 20.1046 13.1046 21 12 21H5C3.89543 21 3 20.1046 3 19V12C3 10.8954 3.89543 10 5 10Z"
              stroke="#000000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </section>
        <div className="social-icon">
          <a href={twitter}>
            <img src={navIcon3} alt="" />
          </a>
          <a href={telegram}>
            <img src={navIcon4} alt="" />
          </a>
          <a href={dexscreener}>
            <img src={navIcon6} alt="" />
          </a>
          <a href={dextools}>
            <img src={navIcon2} alt="" />
          </a>
        </div>
      </Col>
    </Row>
  );
}

function TopDivider() {
  return <div className="skew-cc"></div>;
}

function BottomDivider() {
  return <div className="skew-c"></div>;
}

function Carousel() {
  return (
    <section id="carousel-section">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="carousel-container">
              <div className="carousel-track">
                {listOfImages.map((img) => (
                  <img
                    style={{ width: "250px", padding: "20px" }}
                    src={img}
                    alt="info"
                  />
                ))}
                {listOfImages.map((img) => (
                  <img
                    style={{ width: "250px", padding: "20px" }}
                    src={img}
                    alt="info"
                  />
                ))}
              </div>
            </div>
            <div className="carousel-container2">
              <div className="carousel-track2">
                {listOfImages.map((img) => (
                  <img
                    style={{ width: "250px", padding: "20px" }}
                    src={img}
                    alt="info"
                  />
                ))}
                {listOfImages.map((img) => (
                  <img
                    style={{ width: "250px", padding: "20px" }}
                    src={img}
                    alt="info"
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

function BuyGuide() {
  return (
    <Row>
      <Col xs={12}>
        <header className="buy-guide">
          <h1>HOW TO BUY?</h1>
          <ul id="buy-list">
            <li>
              <span>1</span>
              <div className="guideItem">
                <h3>Create a wallet</h3>
                <p>
                  Download Phantom or your wallet of choice from the app store
                  or google play store for free. Desktop users, download the
                  google chrome extension by going to phantom app
                </p>
                <a
                  href="https://phantom.app/"
                  className="itemBtn"
                  style={{
                    boxShadow: "-5px 6px 0 0 #3b325b",
                    backgroundColor: "#ab9ff2",
                  }}
                >
                  PHANTOM
                  <svg
                    className="css-1a1mo8u e18osog01"
                    width="35"
                    height="30"
                    viewBox="65 30 30 120"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M89.1138 112.613C83.1715 121.719 73.2139 133.243 59.9641 133.243C53.7005 133.243 47.6777 130.665 47.6775 119.464C47.677 90.9369 86.6235 46.777 122.76 46.7764C143.317 46.776 151.509 61.0389 151.509 77.2361C151.509 98.0264 138.018 121.799 124.608 121.799C120.352 121.799 118.264 119.462 118.264 115.756C118.264 114.789 118.424 113.741 118.746 112.613C114.168 120.429 105.335 127.683 97.0638 127.683C91.0411 127.683 87.9898 123.895 87.9897 118.576C87.9897 116.642 88.3912 114.628 89.1138 112.613ZM115.936 68.7103C112.665 68.7161 110.435 71.4952 110.442 75.4598C110.449 79.4244 112.689 82.275 115.96 82.2693C119.152 82.2636 121.381 79.4052 121.374 75.4405C121.367 71.4759 119.128 68.7047 115.936 68.7103ZM133.287 68.6914C130.016 68.6972 127.786 71.4763 127.793 75.4409C127.8 79.4055 130.039 82.2561 133.311 82.2504C136.503 82.2448 138.732 79.3863 138.725 75.4216C138.718 71.457 136.479 68.6858 133.287 68.6914Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </a>
              </div>
            </li>
            <li>
              <span>2</span>
              <div className="guideItem">
                <h3>Buy SOL</h3>
                <p>
                  Have SOL in your wallet to switch to {ticker} If you dont have
                  any SOL, you can buy SOL from an exchange or cross chain swap
                  and send it to your wallet.
                </p>
              </div>
            </li>
            <li>
              <span>3</span>
              <div className="guideItem">
                <h3>Go to a DEX</h3>
                <p>
                  Connect to Raydium. Go raydium.io in google chrome or on the
                  browser inside your Phantom app. Connect your wallet. Paste
                  the {ticker} token address into Raydium and confirm the swap.
                  When Phantom prompts you for a wallet signature, sign.
                </p>
              </div>
            </li>
            <li>
              <span>4</span>
              <div className="guideItem">
                <h3>SWAP</h3>
                <p>Switch SOL for {ticker}</p>
              </div>
            </li>
            <li>
              <div>
                <a href={buylink} id="buy-btn" className="btn">
                  GET LENNY?
                </a>
              </div>
            </li>
          </ul>
        </header>
      </Col>
    </Row>
  );
}

function Footer() {
  return (
    <footer className="footer">
      <div style={{ margin: "20px", textAlign: "center" }}>{footercontent}</div>
      <div
        style={{
          paddingTop: "50px",
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "Bold",
          color: "#000",
        }}
      >
        NEED A WEBSITE?
      </div>
      <div
        style={{
          margin: "20px",
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "#000",
        }}
      >
        Twitter:
        <a
          href="https://x.com/0xChrisDegen"
          style={{ color: "#ffb8e4", paddingLeft: "5px" }}
        >
          0xChrisDegen
        </a>
      </div>
      <div
        style={{
          margin: "20px",
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "#000",
        }}
      >
        Telegram:
        <a
          href="https://t.me/OxDegenChris"
          style={{ color: "#ffb8e4", paddingLeft: "5px" }}
        >
          OxDegenChris
        </a>
      </div>
    </footer>
  );
}

function TickerTape() {
  return (
    <div className="ticker-wrap">
      <div className="ticker">
        <div className="ticker__item">LENNY</div>
        <div className="ticker__item">( ͡º ͜ʖ ͡º)</div>
        <div className="ticker__item">LENNY</div>
        <div className="ticker__item">( ͡º ͜ʖ ͡º)</div>
        <div className="ticker__item">LENNY</div>
        <div className="ticker__item">( ͡º ͜ʖ ͡º)</div>
        <div className="ticker__item">LENNY</div>
        <div className="ticker__item">( ͡º ͜ʖ ͡º)</div>
        <div className="ticker__item">LENNY</div>
        <div className="ticker__item">( ͡º ͜ʖ ͡º)</div>
        <div className="ticker__item">LENNY</div>
      </div>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
